<template>
    <div class="fixed customer-list" v-loading="isLoading">
        <div class="over-view-data" ref="overViewData">
            <!-- <customerDataOverview
                :overViewDataLeftNum="overViewData.effectiveCustomerNum"
                :overViewDataRightNum="overViewData.customersTotal"
                :isShowArrowIcon="false"
            /> -->
            <business-opportunity
                :showMyData="true"
                :isGray="isGray"
                :isInList="isInList"
                :businessData="overViewData"
                :isActivity="false"
                :microCouponData="{}"
                :isCoupon="false"
            />
        </div>
        <!-- 排行列表 -->
        <div class="rank" ref="rank">
            <rank-list
                v-if="isInList"
                :visitor-data="visitorRankData"
                :business-data="businessRankData"
                :user-visitor-data="userVisitorRankData"
                :user-business-data="userBusinessRankData"
                :active-tab="activeTab"
                @businessRank="getBusinessRankData"
                @tab-click="switchTab"
            />
        </div>

        <div class="effective-list">
            <div class="tab-wrap">
                <!-- <van-tabs
                    v-model="active"
                    @change="switchTab"
                    sticky
                    swipeable
                    line-width=".32rem"
                    line-height=".06rem"
                > -->
                <div class="search" :style="searchStyle">
                    <van-search
                        :left-icon="'https://wechatapppro-1252524126.file.myqcloud.com/apprnDA0ZDw4581/image/b_u_5b2225aa46488_oGKN7IvA/kyxvd9gt008t.png'"
                        @click="goToSearchCustomer()"
                        placeholder="搜索"
                    />
                </div>
                <!-- <p class="searchIcon" @click="goToSearchCustomer()">
                        <img src="@/assets/images/searchIcon.png" />
                    </p> -->
                <!-- <van-tab v-for="(tab, idx) of tabs" :title="tab.label" :key="idx" :name="tab.name"></van-tab> -->
                <van-list v-model="loading" :finished="finished" @load="onLoad" :immediate-check="false">
                    <render-list
                        :isInList="isInList"
                        :customerList="customerInfoList"
                        :isGray="isGray"
                        bg-color="#fff"
                        :needClue="true"
                        :isLoaded="isLoaded"
                        :is-visitors="true"
                        :active-tab="0"
                    ></render-list>
                    <!-- <div class="invalidPromotion" v-if="showEmptyPage">
                        <img src="@/assets/images/promotion_invalid.png" alt="" />
                        <p>{{ emptyMsg }}</p>
                    </div> -->
                </van-list>
                <div style="display: flex; justify-content: center; color: #999999" v-show="finished && !showEmptyPage">
                    没有更多了~
                </div>
                <!-- </van-tabs> -->
            </div>
        </div>
        <promotionCenterBtn />
    </div>
</template>
<script>
import businessOpportunity from '../activityDetail/components/businessOpportunity.vue';
// import customerDataOverview from '@/views/staffPromotion/components/customerDataOverview';
// import customerInfo from '@/views/staffPromotion/page/customerList/components/customerInfo';
import { fetchCustomerList, fetchBusinessCustomerList, getBusinessCustomerTotal } from '@/views/staffPromotion/api';
import permission from '@/mixins/permission';
import promotionCenterBtn from '@/views/staffPromotion/components/promotionCenterBtn';
import renderList from '../activityDetail/components/general/renderList.vue';
import rankList from '../../components/rankList.vue';
import { getCookie } from '@/utils/cookies';
import { tools } from '@/utils/tools';

const TABS = [
    {
        label: '有效客户',
        name: 'effectiveCustomer'
    },
    {
        label: '失效客户',
        name: 'invalidCustomer'
    }
];
export default {
    mixins: [permission],
    components: {
        // customerDataOverview,
        // customerInfo,
        promotionCenterBtn,
        businessOpportunity,
        renderList,
        rankList
    },
    data() {
        return {
            isInList: false,
            isGray: false,
            active: TABS[0].name,
            tabs: TABS,
            isLoaded: false,
            customerInfoList: [],
            loading: false, //上拉加载
            finished: false, //上拉加载完毕
            overViewData: {
                total_number: 0,
                today_number: 0,
                count_all: 0,
                count_today: 0
            },
            page: 1,
            pageSize: 10,
            customerState: 1, // 0- 失效客户 1- 有效客户
            isLoading: true,
            showEmptyPage: false,
            emptyMsg: '暂无有效客户',
            showGoCenterBtn: false,
            isShowList: false,
            // 排行榜数据
            visitorRankData: [],
            userVisitorRankData: {},
            businessRankData: [],
            userBusinessRankData: {},
            noMoreData: false,
            currentType: {},
            searchStyle: {},
            activeTab: 0,
            currentScrollTop: 0,
            isRefresh: true
        };
    },
    watch: {
        loading(newval) {
            newval ? (this.isShowList = false) : (this.isShowList = true);
        }
    },
    computed: {
        businessDetail() {
            return this.$store.getters.getBusinessDetail;
        },
        isEffectiveCustomer() {
            return this.customerState ? true : false;
        }
    },
    beforeRouteLeave(to, from, next) {
        const keepAliveList = ['customerDetail'];
        this.isRefresh = keepAliveList.indexOf(to.name) < 0;

        console.log(this.isRefresh)

        this.currentScrollTop = this.isRefresh ? 0 : document.documentElement.scrollTop || document.body.scrollTop;
        next();
    },
    async activated() {
        if (!this.isRefresh) {
            document.documentElement.scrollTop = this.currentScrollTop;
            return;
        }

        const { type } = this.$route.query;
        this.getActiveTab(type);
        this.getOverViewData();


        this.isLoading = true;
        await this.hasPermission((data) => {
            const { is_distributor, status, manage_info } = data;
            if (is_distributor === 0 && status === 2) {
                this.$router.replace({
                    name: 'noPermissionPage',
                    query: {
                        manager: manage_info.name
                    }
                });
            }
            if (is_distributor === 1 && status === 2) {
                this.$router.replace({
                    name: 'noPermissionPage',
                    query: {
                        manager: manage_info.name
                    }
                });
            }
            if (is_distributor === 0 && status === 0) {
                this.$router.replace({
                    name: 'noPermissionPage',
                    query: {
                        manager: manage_info.name
                    }
                });
            }
        });
        this.initCustomerList();
        this.getVisitorRankData();
    },
    mounted() {
        // search ICON 定位在tab栏上
        console.log('----mounted----');
        let tabs__wrap = document.querySelector('.van-tabs__wrap');
        let searchIcon = document.querySelector('.searchIcon');
        tabs__wrap && tabs__wrap.appendChild(searchIcon);
        this.isLoading = true;
        this.listenScroll();
    },
    methods: {
        getActiveTab(tab) {
            if (tab === 'customer') {
                this.activeTab = 0;
            }
            if (tab === 'merchant') {
                this.activeTab = 1;
            }
        },
        getOverViewData() {
            getBusinessCustomerTotal({ app_id: tools.getAppId(), user_id: tools.getUserId() }).then((res) => {
                const { data = {} } = res;
                this.overViewData = data;
            });
        },
        listenScroll() {
            const { overViewData, rank } = this.$refs;
            if (overViewData && rank) {
                window.addEventListener('scroll', () => {
                    this.handleScroll(overViewData, rank);
                });
            }
        },
        handleScroll(overViewData, rank) {
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            const overViewDataHeight = overViewData.clientHeight;
            const rankHeight = rank.clientHeight;
            let pos = {};
            if (scrollTop > overViewDataHeight + rankHeight) {
                pos = { position: 'fixed' };
            } else {
                pos = { position: 'static' };
            }
            this.searchStyle = pos;
        },
        switchTab(tab) {
            this.page = 1;
            this.customerInfoList = [];
            /*this.showEmptyPage = false;
            name === 'invalidCustomer' ? (this.customerState = 0) : (this.customerState = 1);*/
            if (!this.isLoading && !this.loading) {
                this.$toast.loading({
                    duration: 0,
                    forbidClick: true,
                    message: '加载中...'
                });
            }
            this.currentType = tab;
            this.initCustomerList('tab-click');
        },
        // 上拉加载
        onLoad() {
            if (!this.noMoreData) {
                this.initCustomerList();
            }
        },

        getCustomerList(params) {
            // 0：锁客  1：商机
            if (this.currentType.code === 1) {
                return fetchBusinessCustomerList(params);
            }
            return fetchCustomerList(params);
        },

        initCustomerList(type) {
            if (type !== 'tab-click') {
                this.loading = true;
                this.isLoading = true;
            }
            const payload = {
                page: this.page,
                page_size: this.pageSize
            };
            this.getCustomerList(payload)
                .then((res) => {
                    // 请求成功了再请求下一页
                    this.page++;
                    this.$toast.clear();
                    this.isLoading = false;
                    const { total } = res?.data;
                    // eslint-disable-next-line no-prototype-builtins
                    if (res.data.hasOwnProperty('open_wework_distribute')) {
                        this.isInList = res?.data?.open_wework_distribute;
                    }
                    // eslint-disable-next-line no-prototype-builtins
                    if (res.data.hasOwnProperty('is_gray_business_opportunities')) {
                        this.isGray = res?.data?.is_gray_business_opportunities;
                    }
                    this.noMoreData = res.data.list && res.data.list.length === 0;

                    if (payload.page === 1) {
                        this.handleCustomerListData(res?.data);
                        if (type !== 'tab-click') {
                            this.isLoaded = true;
                        }
                        this.loading = false;
                        this.finished = false;
                    } else {
                        this.pushCustomerData(res?.data?.list);
                        if (type !== 'tab-click') {
                            this.isLoaded = true;
                        }
                        this.loading = false;
                    }
                    if (this.customerInfoList.length >= total) {
                        this.finished = true;
                        // this.loading = true;
                    }
                    this.$forceUpdate();
                })
                .catch((error) => {
                    console.log('err', error);
                    this.$toast.fail('获取失败');
                    this.isLoading = false;
                    this.loading = false;
                });
        },
        // 获取总排行榜访客数据
        getVisitorRankData() {
            const params = {
                app_id: tools.getAppId(),
                user_id: tools.getUserId()
            };
            this.$ajax('get_main_invite_rank', params)
                .then((res) => {
                    if (res.data.code === 0) {
                        this.visitorRankData = res.data.data.list;
                        this.userVisitorRankData = res.data.data.my;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        // 获取总排行榜商机数据
        getBusinessRankData() {
            const params = {
                app_id: getCookie('with_app_id'),
                user_id: localStorage.getItem('userId')
            };
            this.$ajax('get_main_business_rank', params)
                .then((res) => {
                    if (res.data.code === 0) {
                        this.businessRankData = res.data.data.list;
                        this.userBusinessRankData = res.data.data.my;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        goToSearchCustomer() {
            this.$router.push({
                path: '/staff_promotion/customer_search'
            });
        },
        handleCustomerListData(data) {
            const { list } = data;
            list.length ? (this.showEmptyPage = false) : (this.showEmptyPage = true);
            /* this.overViewData = {
                total_number: total_number ?? this.overViewData.total_number, // 商机对应的访客是没有这些字段的，所以兼容处理一下
                today_number: today_number ?? this.overViewData.today_number,
                count_all: count_all ?? this.overViewData.count_all,
                count_today: count_today ?? this.overViewData.count_today
            };*/
            this.customerInfoList = list;
            this.customerState && !this.initCustomerList.length
                ? (this.emptyMsg = '暂无有效客户')
                : (this.emptyMsg = '暂无失效客户');
        },
        pushCustomerData(list) {
            this.customerInfoList = [...this.customerInfoList, ...list];
        },
        goPromotionCenter() {
            this.$router.push({
                path: '/staff_promotion'
            });
        }
    },
    beforeRouteEnter(to, from, next) {
        if (from.path != '/staff_promotion' && from.path != '/staff_promotion/customer_detail')
            next((vm) => {
                vm.showGoCenterBtn = true;
            });
        next();
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    }
};
</script>
<style lang="scss" scoped>
.customer-list {
    height: 100%;
    width: 100%;
    overflow: visible;
    background: #f6f6f6;
    position: absolute;
    .over-view-data {
        margin: 0.32rem;
    }
    .rank {
        margin-bottom: 0.32rem;
    }
    .effective-list {
        background: #fff;
        position: relative;
        border-top-left-radius: 0.32rem;
        border-top-right-radius: 0.32rem;
        height: 100vh;
        width: 100%;
        .tab-wrap {
            .search {
                width: 100%;
                top: 0;
                z-index: 1;
                transition: top 0.1s linear;
            }
        }
        ::v-deep .van-icon {
            display: flex;
            align-items: center;
        }
        ::v-deep .van-search .van-search__content {
            height: 0.64rem;
        }
        ::v-deep .van-search {
            padding: 0.16rem 0.32rem;
            border-top-left-radius: 0.32rem;
            border-top-right-radius: 0.32rem;
            //position: sticky;
            top: 0;
        }
        ::v-deep .van-tabs {
            padding-top: 0.32rem;
        }
        ::v-deep .van-tabs__wrap {
            border-radius: 0.2rem;
            position: relative;
            height: 0.5rem;
            .van-tab {
                display: inline-block;
                border-radius: 0.32rem;
                margin-left: 0.2rem;
            }
            .van-tab--active {
                text-align: center;
            }
            .van-tabs__line {
                background-color: #fd4e1e;
                border-radius: 20% !important;
            }
            .van-tabs__nav {
                position: relative;
                width: 100%;
                display: inline-block;
                text-align: left;
            }
            .searchIcon {
                position: absolute;
                right: 0.32rem;
                top: 0;
                width: 0.32rem;
                height: 0.32rem;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        ::v-deep .van-sticky--fixed {
            padding: 0.4rem 0.2rem 0;
            background: #fff;
            margin: auto;
            max-width: 480px;
        }
        ::v-deep .van-tabs__content {
            padding-top: 0.2rem;
            .van-list {
                background: #ffffff;
            }
        }
    }
    .customer-info {
        padding-top: 0;
    }
    button {
        position: relative;
        top: 0;
        left: 0;
        float: right;
    }
    .navBarWrap {
        position: fixed;
        top: 0;
        z-index: 999;
    }
    .invalidPromotion {
        text-align: center;
        margin: 0.6rem 0;
        padding: 0.6rem 0;
        img {
            width: 2rem;
            height: 2rem;
        }
        p {
            color: #999999;
        }
    }
    .back-center {
        position: fixed;
        right: 0.32rem;
        bottom: 2rem;
        width: 1.2rem;
        height: 1.2rem;
        background: #fff;
        border-radius: 50%;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08);
        z-index: 99;
        display: flex;
        justify-content: center;
        align-items: center;
        div {
            font-size: 0.28rem;
            color: #333333;
            word-wrap: break-word;
            width: 0.56rem;
            height: 0.8rem;
        }
    }
}
</style>
